import React from 'react';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

const Pop = styled(Popup)`
  && {
    opacity: 0.7;
  }
`;

export default ({ message, node }) =>
  message ? <Pop inverted trigger={node} content={message} /> : node;

import React, { Component } from 'react';
import { Footer } from '../styles';
import ParnerLogo from '../../Content/Shared/PartnerLogo';

class MFooterEidsiva extends Component {
  render() {
    const { getTranslation } = this.props;
    return (
      <Footer background="#e1124d">
        <ParnerLogo getTranslation={(messageCode) => getTranslation(messageCode)} />
      </Footer>
    );
  }
}

export default MFooterEidsiva;

import React, { Component } from 'react';
import { Header } from './styles';
import Social from './Social';
import SubscribeForm from './Form';

class Connect extends Component {
  render() {
    const { body } = this.props.getTranslation('PASSWORD_RESET_CONNECT_EMAIL') || { body: '' };

    return body ? (
      <div>
        <Header>Connect with us</Header>
        <Social />
        <SubscribeForm />
      </div>
    ) : (
      ''
    );
  }
}

export default Connect;

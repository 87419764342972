import React from 'react';
import { Formik } from 'formik';
import { Submit, Subscribe, Img } from './styles';
import Arrow from '../../assets/Arrow.svg';
import * as Yup from 'yup';
import Popup from './Popup';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('This is not valid email address')
    .required('This field is required!'),
});

export default () => (
  <Formik
    validationSchema={schema}
    onSubmit={(_, actions) => actions.resetForm()}
    initialValues={{ email: '' }}
    render={({ values, errors, handleBlur, handleChange, handleSubmit, isValid, touched }) => (
      <form onSubmit={handleSubmit}>
        <Submit>
          <Subscribe
            color={!!errors.email && touched.email ? '#F25652' : '#2494c3'}
            type="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            placeholder="Enter email to subscribe"
          />
          <Popup
            message={errors.email}
            node={<Img type="image" src={Arrow} alt="Submit" disabled={!isValid} />}
          />
        </Submit>
      </form>
    )}
  />
);

import React, { Component } from 'react';
import { Divider, Grid } from 'semantic-ui-react';
import { Footer, FooterSpan } from './styles';
import Contact from './Contact';
import Connect from './Connect';
import Download from './Download';
import {
  HomelyApplicationId,
  TrygApplicationId,
  EidsivaApplicationId,
  TekamApplicationId,
  CopiaxApplicationId,
  IotilitiApplicationId,
  SafelyApplicationId,
  KeyfreeApplicationId,
  WaooApplicationId,
  ForebyggApplicationId,
  NimlyApplicationId,
} from '../../utils/applications';
import MFooterHomely from './Customization/Homely';
import MFooterEidsiva from './Customization/Eidsiva';
import EmptyFooter from './Customization/EmptyFooter';
import ParnerLogo from '../Content/Shared/PartnerLogo';
import MFooterSafely from './Customization/Safely';
import MFooterEasyaccess from './Customization/Nimly';

const { Column } = Grid;

class MFooter extends Component {
  render() {
    const { getTranslation } = this.props;

    switch (this.props.companyId) {
      case HomelyApplicationId:
        return <MFooterHomely getTranslation={(messageCode) => getTranslation(messageCode)} />;
      case NimlyApplicationId:
        return <MFooterEasyaccess getTranslation={(messageCode) => getTranslation(messageCode)} />;
      case TrygApplicationId:
        return (
          <EmptyFooter
            getTranslation={(messageCode) => getTranslation(messageCode)}
            background="white"
          />
        );
      case TekamApplicationId:
      case CopiaxApplicationId:
      case IotilitiApplicationId:
        return <EmptyFooter getTranslation={(messageCode) => getTranslation(messageCode)} />;
      case EidsivaApplicationId:
        return <MFooterEidsiva getTranslation={(messageCode) => getTranslation(messageCode)} />;
      case SafelyApplicationId:
        return <MFooterSafely getTranslation={(messageCode) => getTranslation(messageCode)} />;
      case KeyfreeApplicationId:
        return (
          <EmptyFooter
            getTranslation={(messageCode) => getTranslation(messageCode)}
            background="#51114D"
          />
        );
      case WaooApplicationId:
        return (
          <EmptyFooter
            getTranslation={(messageCode) => getTranslation(messageCode)}
            background="#282828"
          />
        );
      case ForebyggApplicationId:
        return (
          <EmptyFooter
            getTranslation={(messageCode) => getTranslation(messageCode)}
            background="#E5F4F5"
          />
        );
      default:
        return (
          <MFooterDefault
            getTranslation={(messageCode) => getTranslation(messageCode)}
            companyId={this.props.companyId}
          />
        );
    }
  }
}

const MFooterDefault = ({ getTranslation, companyId }) => {
  const { body } = getTranslation('RESET_PASSWORD_COPYRIGHT_NOTICE') || { body: '' };
  return (
    <Footer>
      <ParnerLogo getTranslation={(messageCode) => getTranslation(messageCode)} />
      <Divider />
      <Grid relaxed columns={3}>
        <Column>
          <Contact
            getTranslation={(messageCode) => getTranslation(messageCode)}
            companyId={companyId}
          />
        </Column>
        <Column>
          <Download getTranslation={(messageCode) => getTranslation(messageCode)} />
        </Column>
        <Column>
          <Connect getTranslation={(messageCode) => getTranslation(messageCode)} />
        </Column>
      </Grid>
      <Divider />
      {body && (
        <FooterSpan color="white">
          @ {body}
          <FooterSpan color="#537EA2">All rights reserved.</FooterSpan>
        </FooterSpan>
      )}
    </Footer>
  );
};

export default MFooter;

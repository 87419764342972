import styled from 'styled-components';
import { Button } from '../../../Shared/styles';
import { Label, Error } from '../../styles';

export const TrygButton = styled(Button)`
  background-color: ${(props) => {
    if (props.submit) {
      return '#ce0303';
    } else {
      return '#D9D9D9';
    }
  }};
  cursor: ${(props) => (props.disable ? 'not-allowed' : 'pointer')};
  color: #fff;
  border-color: #460101;
  fill: #fff;
  font-family: 'Segoe UI';

  &:hover {
    color: #fff;
    background-color: ${(props) => {
      return props.submit ? '#9c0202' : '#D9D9D9';
    }}
    border-color: ${(props) => {
      return props.submit ? '#460101' : 'inherit';
    }}
    box-shadow: none !important;
    
  }

  &:focus {
    color: #fff;
    background-color: ${(props) => {
      return props.submit ? '#9c0202' : '#D9D9D9';
    }}
    border-color: ${(props) => {
      return props.submit ? '#050000' : 'inherit';
    }}
    box-shadow: none !important;
  }
`;

export const TrygLabel = styled(Label)`
  color: #222222;
  font-family: 'Segoe UI';
  box-shadow: none;
`;

export const TrygError = styled(Error)`
  font-family: 'Segoe UI';
`;

import styled from 'styled-components';

export const Header = styled.span`
  display: block;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin-left: ${(props) => props.customMargin ?? 0};
`;

export const Info = styled.span`
  color: #537ea2;
  font-size: 14px;
  line-height: 22px;
  display: block;
  margin: 30px 0;
`;

export const NoMarginInfo = styled.span`
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  display: block;
`;

export const Subscribe = styled.input`
  border: 0.7px solid ${(props) => props.color};
  border-width: 0.7px;
  width: 100%;
  border-radius: 25px;
  background-color: #ffffff;
  padding: 12px 0 12px 27px;
  color: #537ea2;
  font-size: 14px;
  line-height: 22px;
  &:focus {
    outline: none;
  }
`;

export const Submit = styled.div`
  position: relative;
  height: 46px;
  width: 100%;
  max-width: 360px;
`;

export const Img = styled.input.attrs({ type: 'image' })`
  outline: none;
  position: absolute;
  z-index: 10;
  top: 0.7px;
  right: 0.7px;
  cursor: pointer;
`;

export const FooterSpan = styled.span`
  color: ${(props) => props.color};
  font-size: 14px;
  line-height: 22px;
`;

export const Footer = styled.footer`
  width: 100%;
  @media only screen and (min-width: 768px) {
    min-height: 420px;
  }
  min-height: 100px;
  background: ${(props) => {
    return props.background
      ? props.background
      : 'linear-gradient(357.41deg, #1e496d 0%, #102e47 100%)';
  }};

  @media only screen and (min-width: 768px) {
    padding: 20px 140px;
  }
  padding: 20px 20px;
`;

import React, { Component } from 'react';
import { Info, Header } from './styles';
import Appstore from '../../assets/Appstore.svg';
import Googleplay from '../../assets/Googleplay.svg';
import styled from 'styled-components';

const Img = styled.img`
  display: inline-block;
  width: 140px;
  height: 40px;
  margin-right: 20px;
`;

class Contact extends Component {
  render() {
    const { body } = this.props.getTranslation('RESET_PASSWORD_DOWNLOAD') || { body: '' };

    return body && body !== '' ? (
      <div>
        <Header>Download the App</Header>
        <Info>
          <div
            dangerouslySetInnerHTML={{
              __html: body,
            }}
          />
        </Info>
        <Img src={Appstore} alt="appstore" />
        <Img src={Googleplay} alt="googleplay" />
      </div>
    ) : (
      ''
    );
  }
}

export default Contact;

import React from 'react';
import { Formik } from 'formik';
import { Icon } from 'semantic-ui-react';
import { Label, ButtonContainer, FormInput, FormField, SubmitButton, Spinner } from './styles';
import { Button } from '../Shared/styles';
import FormTryg from './Customization/Tryg/FormTryg';
import FormNeutral from './Customization/Neutral/FormNeutral';
import schema from './validationSchema';
import Error from './Error';
import {
  TrygApplicationId,
  CopiaxApplicationId,
  IotilitiApplicationId,
  TekamApplicationId,
  HomelyApplicationId,
  NimlyApplicationId,
} from '../../../utils/applications';

export default ({
  isNewPasswordVisible,
  isConfirmPasswordVisible,
  onToggleVisibility,
  onSubmit,
  companyId,
}) => {
  switch (companyId) {
    case TrygApplicationId:
      return (
        <FormTryg
          isNewPasswordVisible={isNewPasswordVisible}
          isConfirmPasswordVisible={isConfirmPasswordVisible}
          onSubmit={onSubmit}
          companyId={companyId}
          onToggleVisibility={onToggleVisibility}
        />
      );
    case CopiaxApplicationId:
    case IotilitiApplicationId:
    case TekamApplicationId:
    case NimlyApplicationId:
      return (
        <FormNeutral
          isNewPasswordVisible={isNewPasswordVisible}
          isConfirmPasswordVisible={isConfirmPasswordVisible}
          onSubmit={onSubmit}
          companyId={companyId}
          onToggleVisibility={onToggleVisibility}
        />
      );
    default:
      return (
        <Formik
          validationSchema={schema}
          onSubmit={onSubmit}
          initialValues={{ newPassword: '', confirmPassword: '' }}
          render={({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isValid,
            resetForm,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <FormField>
                <Label>New password*</Label>
                <FormInput
                  type={isNewPasswordVisible ? 'text' : 'password'}
                  name="newPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPassword}
                  placeholder="New password"
                  icon={
                    <Icon
                      name="eye"
                      link
                      onClick={() => onToggleVisibility('isNewPasswordVisible')}
                    />
                  }
                />
                {errors.newPassword && touched.newPassword && (
                  <Error message={errors.newPassword} />
                )}
              </FormField>
              <FormField>
                <Label>Re-new password*</Label>
                <FormInput
                  type={isConfirmPasswordVisible ? 'text' : 'password'}
                  name="confirmPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  placeholder="Re-new password"
                  icon={
                    <Icon
                      name="eye"
                      link
                      onClick={() => onToggleVisibility('isConfirmPasswordVisible')}
                    />
                  }
                />
                {errors.confirmPassword && touched.confirmPassword && (
                  <Error message={errors.confirmPassword} />
                )}
              </FormField>
              <ButtonContainer>
                <Button type="button" onClick={() => resetForm()}>
                  Reset form
                </Button>
                <SubmitButton>
                  <Button
                    submit
                    type="submit"
                    disable={!isValid || isSubmitting}
                    background={companyId === HomelyApplicationId ? '#0f6f5c' : '#2ec9ad'}
                  >
                    Confirm
                  </Button>
                  {isSubmitting ? <Spinner name="circle notch" loading /> : null}
                </SubmitButton>
              </ButtonContainer>
            </form>
          )}
        />
      );
  }
};

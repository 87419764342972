import React from 'react';
import Ok from '../../../assets/Ok.png';
import { Container, Img, Info } from '../Shared/styles';
import Header from '../Shared/Header';

export default ({ companyId }) => (
  <Container>
    <Img src={Ok} alt="ok" />
    <Header companyId={companyId} />
    <Info>
      Success! Your password has been successfully
      <br /> changed to new one.
    </Info>
  </Container>
);

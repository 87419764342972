import React from 'react';
import { Formik } from 'formik';
import { Icon } from 'semantic-ui-react';
import { ButtonContainer, FormInput, FormField, SubmitButton, Spinner } from '../../styles';
import { TrygButton, TrygLabel } from './styles';
import schema from '../../validationSchema';
import TrygError from './TrygError';
import './styles.css';

export default ({
  isNewPasswordVisible,
  isConfirmPasswordVisible,
  onToggleVisibility,
  onSubmit,
  companyId,
}) => (
  <Formik
    validationSchema={schema}
    onSubmit={onSubmit}
    initialValues={{ newPassword: '', confirmPassword: '' }}
    render={({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      isValid,
      resetForm,
      isSubmitting,
    }) => (
      <form onSubmit={handleSubmit}>
        <FormField>
          <TrygLabel>New password*</TrygLabel>
          <FormInput
            type={isNewPasswordVisible ? 'text' : 'password'}
            name="newPassword"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.newPassword}
            placeholder="New password"
            icon={
              <Icon name="eye" link onClick={() => onToggleVisibility('isNewPasswordVisible')} />
            }
          />
          {errors.newPassword && touched.newPassword && <TrygError message={errors.newPassword} />}
        </FormField>
        <FormField>
          <TrygLabel>Re-new password*</TrygLabel>
          <FormInput
            type={isConfirmPasswordVisible ? 'text' : 'password'}
            name="confirmPassword"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirmPassword}
            placeholder="Re-new password"
            icon={
              <Icon
                name="eye"
                link
                onClick={() => onToggleVisibility('isConfirmPasswordVisible')}
              />
            }
            fontFamily="Segoe UI"
          />
          {errors.confirmPassword && touched.confirmPassword && (
            <TrygError message={errors.confirmPassword} />
          )}
        </FormField>
        <ButtonContainer>
          <TrygButton type="button" onClick={() => resetForm()}>
            Reset form
          </TrygButton>
          <SubmitButton>
            <TrygButton submit type="submit" disable={!isValid || isSubmitting}>
              Confirm
            </TrygButton>
            {isSubmitting ? <Spinner name="circle notch" loading /> : null}
          </SubmitButton>
        </ButtonContainer>
      </form>
    )}
  />
);

import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export default ({ component: Component, isValidToken, companyId, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isValidToken ? <Component {...props} companyId={companyId} /> : <Redirect to="/error" />
    }
  />
);

import React, { Component } from 'react';
import { Layout } from './styles';
import Routes from '../../Routes';
import { withRouter } from 'react-router-dom';

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  render() {
    const { isValidToken, companyId } = this.props;
    return (
      <Layout>
        <Routes isValidToken={isValidToken} companyId={companyId} />
      </Layout>
    );
  }
}

export default withRouter(Content);

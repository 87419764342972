import styled from 'styled-components';

export const StyledHeader = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
  z-index: 101;
  background: ${(props) =>
    props.background ? props.background : 'linear-gradient(0deg, #1e496d 0%, #102e47 100%)'};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 5px 20px 0 rgba(119, 205, 248, 0.6)'};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
`;

import { Container } from 'semantic-ui-react';
import styled from 'styled-components';

export const Layout = styled(Container)`
  && {
    flex-grow: 1;
    min-height: 470px;
    display: flex;
    justify-content: left;
    align-items: center;
  }
`;

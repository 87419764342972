import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'Password is too short!')
    .matches(/.*[0-9].*/, 'Password must contain at least one number!')
    .matches(/\w*[A-Z]\w*/, 'Password must contain at least one uppercase letter!')
    .required('This field is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], "Passwords don't match")
    .required('This field is required'),
});

export default validationSchema;

import React, { Component } from 'react';
import { Divider, Grid } from 'semantic-ui-react';
import { Footer, NoMarginInfo } from '../styles';
import styled from 'styled-components';

const { Column } = Grid;

const Img = styled.img`
  margin-left: 100px;
`;

class MFooterEasyaccess extends Component {
  render() {
    const { getTranslation } = this.props;
    const { body } = getTranslation('RESET_PASSWORD_PARTNER_LOGO') || {
      body: '',
      variables: undefined,
    };
    const { body: contact } = this.props.getTranslation('RESET_PASSWORD_CONTACT') || { body: '' };
    return (
      <Footer background="#2D5A50">
        <Img src={body} alt="partner_logo" width="160px" />
        <Divider />
        <Column>
          <div>
            <NoMarginInfo>
              <div
                dangerouslySetInnerHTML={{
                  __html: contact.replace('color:#7c7c7c;', ''),
                }}
              />
            </NoMarginInfo>
          </div>
        </Column>
      </Footer>
    );
  }
}

export default MFooterEasyaccess;

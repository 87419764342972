import React, { Component } from 'react';
import { Footer } from '../styles';
import styled from 'styled-components';
import { Divider, Grid } from 'semantic-ui-react';
import Contact from './Contact';
const { Column } = Grid;

const Img = styled.img`
  margin-left: 0;
`;

class MFooterSafely extends Component {
  render() {
    const { getTranslation } = this.props;
    const { body } = getTranslation('RESET_PASSWORD_PARTNER_LOGO') || {
      body: '',
      variables: undefined,
    };
    return (
      <Footer background="#808080">
        <Img src={body} alt="partner_logo" width="136px" />
        <Divider />
        <Column>
          <Contact getTranslation={(messageCode) => getTranslation(messageCode)} />
        </Column>
      </Footer>
    );
  }
}

export default MFooterSafely;

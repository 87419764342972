import React, { Component } from 'react';
import { Footer } from '../styles';
import ParnerLogo from '../../Content/Shared/PartnerLogo';

class MFooterEmpty extends Component {
  render() {
    const { getTranslation } = this.props;
    return (
      <Footer background={this.props.background}>
        <ParnerLogo getTranslation={(messageCode) => getTranslation(messageCode)} />
      </Footer>
    );
  }
}

export default MFooterEmpty;

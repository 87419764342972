import styled from 'styled-components';
import { lighten } from 'polished';

export const Header = styled.span`
  display: inline-block;
  height: 46px;
  font-weight: normal;
  font-size: 2.375rem;
  color: #7c7c7c;
`;

export const Bold = styled.span`
  font-weight: 500;
`;

export const Button = styled.button`
  height: 40.5px;
  width: 160.5px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => {
    if (props.submit) {
      if (props.background) {
        return props.disable ? lighten(0.3, props.background) : props.background;
      }
      return props.disable ? lighten(0.3, '#2ec9ad') : '#2ec9ad';
    } else {
      return '#D9D9D9';
    }
  }};
  cursor: ${(props) => (props.disable ? 'not-allowed' : 'pointer')};
  color: #ffffff;
  font-size: 18px;
  font-weight: 800;
  box-shadow: none;
  transition: box-shadow 0.2s;
  display: inline-block;
  margin-right: ${(props) => {
    if (props.submit) {
      return '0px;';
    }
    return '20px';
  }};
  &:hover {
    box-shadow: 0 5px 25px 0 rgba(119, 205, 248, 0.8);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Img = styled.img`
  margin-bottom: 25px;
  height: 170px;
  width: 170px;
`;

export const Info = styled.span`
  color: #537ea2;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
`;

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Guarded from './Guard';
import Form from '../Landing/Content/Form';
import Ok from '../Landing/Content/Ok';
import Error from '../Landing/Content/Error';

export default ({ isValidToken, companyId }) => {
  return (
    <Switch>
      <Guarded exact path="/" component={Form} isValidToken={isValidToken} companyId={companyId} />
      <Guarded path="/ok" component={Ok} isValidToken={isValidToken} companyId={companyId} />
      <Route path="/error" component={Error} companyId={companyId} />
    </Switch>
  );
};

import React from 'react';
import Twitter from '../../assets/Twitter.svg';
import Facebook from '../../assets/Facebook.svg';
import Googleplus from '../../assets/Googleplus.svg';
import Instagram from '../../assets/Instagram.svg';
import styled from 'styled-components';

const Img = styled.img`
  display: inline-block;
  height: 35px;
  width: 35px;
  cursor: pointer;
  opacity: 0.2;
  transition: opacity 0.5s;
  margin-right: 20px;
  &:hover {
    opacity: 1;
  }
`;

const Container = styled.div`
  margin: 20px 0 30px;
`;

export default () => (
  <Container>
    <Img src={Twitter} />
    <Img src={Facebook} />
    <Img src={Instagram} />
    <Img src={Googleplus} />
  </Container>
);

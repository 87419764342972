import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Landing from './Landing';

class App extends Component {
  render() {
    const params = new URL(window.location.href).searchParams;
    const token = params.get('token');

    return (
      <BrowserRouter>
        <Landing token={token} />
      </BrowserRouter>
    );
  }
}

export default App;

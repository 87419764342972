import React, { Component } from 'react';
import Form from './Form';
import { Wrapper } from './styles';
import Header from '../Shared/Header';
import { withRouter } from 'react-router-dom';
import client from '../../../Http';

class ResetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewPasswordVisible: false,
      isConfirmPasswordVisible: false,
    };
  }

  onToggleVisibility = (propName) => this.setState({ [propName]: !this.state[propName] });

  onSubmit = async (values) => {
    try {
      const token = this.props.location.search.split('=').pop();
      await client.post('users/reset-password', {
        token,
        password: values.newPassword,
      });
      this.props.history.push('/ok');
    } catch (err) {
      console.log(err.message);
    }
  };

  render() {
    const { isNewPasswordVisible, isConfirmPasswordVisible } = this.state;
    return (
      <div>
        <Header companyId={this.props.companyId} />
        <Wrapper>
          <Form
            isNewPasswordVisible={isNewPasswordVisible}
            isConfirmPasswordVisible={isConfirmPasswordVisible}
            onToggleVisibility={this.onToggleVisibility}
            onSubmit={this.onSubmit}
            companyId={this.props.companyId}
          />
        </Wrapper>
      </div>
    );
  }
}

export default withRouter(ResetForm);

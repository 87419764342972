import React from 'react';
import { Container, Img, Header, Info } from '../Shared/styles';
import Error from '../../../assets/Error.png';

export default () => {
  return (
    <Container>
      <Img src={Error} alt="error" />
      <Header>Token has expired!</Header>
      <Info>
        Please click 'reset password' at your application
        <br /> to get new email allowing to reset password.
      </Info>
    </Container>
  );
};

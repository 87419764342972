import React from 'react';
import { Header } from './styles';
import { TrygApplicationId, CopiaxApplicationId } from '../../../utils/applications';
import TrygHeader from './Customization/Tryg/TrygHeader';
import CopiaxHeader from './Customization/Copiax/CopiaxHeader';

export default ({ companyId }) => {
  switch (companyId) {
    case TrygApplicationId:
      return <TrygHeader>Reset password</TrygHeader>;
    case CopiaxApplicationId:
      return <CopiaxHeader>Reset password</CopiaxHeader>;
    default:
      return <Header>Reset password</Header>;
  }
};

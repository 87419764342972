import React, { Component } from 'react';
import { NoMarginInfo, Header } from '../styles';

class Contact extends Component {
  render() {
    const { body } = this.props.getTranslation('RESET_PASSWORD_CONTACT') || { body: '' };

    return body ? (
      <div>
        <Header>Contact us</Header>
        <NoMarginInfo>
          <div
            dangerouslySetInnerHTML={{
              __html: body,
            }}
          />
        </NoMarginInfo>
      </div>
    ) : (
      ''
    );
  }
}

export default Contact;

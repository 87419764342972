import React, { Component } from 'react';
import { StyledHeader } from '../styles';
import ParnerLogo from '../../Content/Shared/PartnerLogo';

class KeyfreeHeader extends Component {
  render() {
    return (
      <StyledHeader background="#51114D" boxShadow="none">
        <ParnerLogo
          getTranslation={(messageCode) => this.props.getTranslation(messageCode)}
          isHeaderLogo={false}
        />
      </StyledHeader>
    );
  }
}

export default KeyfreeHeader;

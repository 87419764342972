import React, { Component } from 'react';
import { Info, Header, NoMarginInfo } from './styles';
import { IotilitiApplicationId } from '../../utils/applications';

class Contact extends Component {
  render() {
    const { body } = this.props.getTranslation('RESET_PASSWORD_CONTACT') || { body: '' };
    const showHeader = this.showHeader();
    return body && body !== '' ? (
      <div>
        {showHeader && <Header>Contact us</Header>}
        {showHeader ? this.showInfo(body) : this.showInfoNoMargin(body)}
      </div>
    ) : (
      ''
    );
  }

  showHeader = () => {
    return this.props.companyId !== IotilitiApplicationId;
  };

  showInfo = (body) => {
    return (
      <Info>
        <div
          dangerouslySetInnerHTML={{
            __html: body,
          }}
        />
      </Info>
    );
  };

  showInfoNoMargin = (body) => {
    return (
      <NoMarginInfo>
        <div
          dangerouslySetInnerHTML={{
            __html: body,
          }}
        />
      </NoMarginInfo>
    );
  };
}

export default Contact;
